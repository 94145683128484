// SUBCRIPTIONS COUNT
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAIL = "GET_SUBSCRIPTIONS_FAIL";

// STUDENTS COUNT
export const GET_STUDENTS = "GET_STUDENTS";
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
export const GET_STUDENTS_FAIL = "GET_STUDENTS_FAIL";

// VIDEOS COUNT
export const GET_VIDEOS = "GET_VIDEOS";
export const GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS";
export const GET_VIDEOS_FAIL = "GET_VIDEOS_FAIL";

// REVENUE COUNT
export const GET_REVENUE = "GET_REVENUE";
export const GET_REVENUE_SUCCESS = "GET_REVENUE_SUCCESS";
export const GET_REVENUE_FAIL = "GET_REVENUE_FAIL";

// RATINGS COUNT
export const GET_RATINGS = "GET_RATINGS";
export const GET_RATINGS_SUCCESS = "GET_RATINGS_SUCCESS";
export const GET_RATINGS_FAIL = "GET_RATINGS_FAIL";

// EARNINGS COUNT
export const GET_EARNINGS = "GET_EARNINGS";
export const GET_EARNINGS_SUCCESS = "GET_EARNINGS_SUCCESS";
export const GET_EARNINGS_FAIL = "GET_EARNINGS_FAIL";